import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Update_users } from "../../../../store/storeReducers/UsersReducer";
import { useOutletContext } from "react-router-dom";
import { get_user, update_user} from "../../../../functions/Usersfunction";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";
import { ImagenStatus } from "../../../../components/ImagenStatus";
import { get_memberships } from "../../../../functions/MembershipsFunction";

const PressUserView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const [memberships, setMemberships] = useState([]);
    const line = useSelector((state) => state.loading.loading.line);
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false
    });
    const [user, setUser] = useState({
        id: '',
        fullname: '',
        email: '',
        password: '',
        active: '',
        roles: '',
        tbl_customer_id: '',
        membership: '',
        start_day: '',
        date:'',
        confirmation: ''
    });
    
    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getUser(param.id);
            getMemberships();
        }
    }, [param.count]);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        }
        )
    }

    const getUser = async (id) => {
        try {
            const res = await get_user({ id, road: 'to-press' });
            if (res.data.user) {
                setUser(res.data.user);
                dispatch(Update_users(res.data.user));
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const getMemberships = async () => {
        try {
            const res = await get_memberships();
            setMemberships(res.data.memberships);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateUser = async () => {
        setLoad({ ...loading, update: true });
        try {
            const dataUser = { ...user, road: 'to-press' };
            const res = await update_user(dataUser);
            setUser(res.data.user);
            dispatch(Update_users(res.data.user));
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data.err) {
                setErr(err.response.data.err);
            } else if (err.response.data.alert) {
                alert(err.response.data.alert);
            }
        }
        setLoad({ ...loading, update: false });
    }

    return (
        <div>
            {
                !line &&
                <table className='table-add' >
                    <tbody>
                        <tr>
                            <td className='fieldlabel'>Active:</td>
                            <td className='fieldlabel'>
                                { ImagenStatus((user.membership))}
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Start day:</td>
                            <td className='fieldlabel'>
                                <span>{user.start_day}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>End day:</td>
                            <td className='fieldlabel'>
                                <span>{user.end_day}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Active date:</td>
                            <td className='fieldlabel'>
                                <select
                                    className='outline-none pd-0-10p'
                                    name='date'
                                    onChange={(e) => handleChange(e)}
                                >
                                    {
                                        [{ id: '', name_date: '-Select your group-', payment: '' }, ...memberships].map((membership, index) => {
                                            return (
                                                <option key={index} value={membership.id}>
                                                    {membership.type_value} {membership.name_date} {membership.id && `${membership.payment}$`}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.date}
                                </span>
                            </div>
                            </td>
                            </tr>
                        <tr>
                        <td className='fieldlabel'>Confirmation:</td>
                        <td className='fieldlabel'>
                            <input
                                className='outline-none pd-0-10p'
                                autoComplete="new-password"
                                name='confirmation'
                                placeholder="yes"
                                onChange={(e) => handleChange(e)}
                            />
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.confirmation}
                                </span>
                            </div>
                        </td>
                        </tr>
                        <tr>
                            <td >
                                <button
                                    disabled={loading.update}
                                    className='select-none'
                                    onClick={() => updateUser()}
                                >
                                    {
                                        loading.update ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Active</div>
                                    }
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    );

}

export default PressUserView;