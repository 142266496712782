export const roles = [
    {
        name: 'admin', value: 'admin'
    },
    {
       name: 'distributor', value: 'distributor'
    },
    {
        name: 'user', value: 'user'
     }
]