import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalDelete from '../../../../components/ModalDelete';
import { Update_users, Delete_users } from "../../../../store/storeReducers/UsersReducer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { get_user, update_user, delete_user } from "../../../../functions/Usersfunction";
import { active } from "../../../../constants/status";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";
import { roles } from "../../../../constants/roles";
import { get_customers } from "../../../../functions/Customerfunction";


const UpdateUserView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [user, setUser] = useState({
        id: '',
        fullname: '',
        email: '',
        password: '',
        active: '',
        roles: '',
        tbl_customer_id: '',
    });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getUser(param.id);
            getCustomers();
        }
    }, [param.count]);

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        }
        )
    }

    const getUser = async (id) => {
        try {
            const res = await get_user({ id, road: 'user' });
            if (res.data.user) {
                setUser(res.data.user);
                dispatch(Update_users(res.data.user));
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const getCustomers = async () => {
        try {
            const custom = {
                road: 'all_customers'
            };
            const res = await get_customers(custom);
            setCustomers(res.data.customers);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateUser = async () => {
        setLoad({ ...loading, update: true });
        try {
            const dataUser = { ...user, road: 'info' };
            const res = await update_user(dataUser);
            dispatch(Update_users(res.data.user));
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const destroyUser = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_user(user.id);
            dispatch(Delete_users(user))
            alert(res.data.alert);
            navigate('/get-users');
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false, modal: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => destroyUser()}
            />
            {
                !line &&
                <table className='table-add' >
                    <tbody>
                        <tr>
                            <td className='fieldlabel'>To Belong:</td>
                            <td className='fieldlabel'>
                                <span>{user && user.user && user.user.email}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Full name:</td>
                            <td className='fieldlabel'>
                                <input
                                    className='outline-none pd-0-10p'
                                    autoComplete="new-password"
                                    defaultValue={user.fullname}
                                    name='fullname'
                                    type="email"
                                    placeholder="Name:"
                                    onChange={(e) => handleChange(e)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Email:</td>
                            <td className='fieldlabel'>
                                <input
                                    className='outline-none pd-0-10p'
                                    autoComplete="new-password"
                                    defaultValue={user.email}
                                    name="email"
                                    type="email"
                                    placeholder="Email:"
                                    onChange={(e) => handleChange(e)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Password:</td>
                            <td className='fieldlabel'>
                                <input
                                    className='outline-none pd-0-10p'
                                    autoComplete="new-password"
                                    name="password"
                                    type="password"
                                    placeholder="New password:"
                                    onChange={(e) => handleChange(e)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Active:</td>
                            <td className='fieldlabel'>
                                <select
                                    className='outline-none pd-0-10p'
                                    name='active'
                                    onChange={(e) => handleChange(e)}
                                    value={user.active}
                                >
                                    {
                                        active.map((a, k) => {
                                            return (
                                                <option key={k} value={a.active}>
                                                    {a.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Rol:</td>
                            <td className='fieldlabel'>
                                <select
                                    className='outline-none pd-0-10p'
                                    name='roles'
                                    onChange={(e) => handleChange(e)}
                                    value={user.roles}
                                >
                                    {
                                        roles.map((a, k) => {
                                            return (
                                                <option key={k} value={a.roles}>
                                                    {a.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                                <td className='fieldlabel'>Customer group:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='tbl_customer_id'
                                        onChange={(e) => handleChange(e)}
                                        value={user.tbl_customer_id}
                                    >
                                        {
                                            [{ id: '', name: '-Select your group-' }, ...customers].map((customer, index) => {
                                                return (
                                                    <option key={index} value={customer.id}>
                                                       
                                                        {customer.name} { customer.id &&  parseInt(customer.percentage || 0)} {customer.id && '%'}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        <tr>
                            <td >
                                <button
                                    disabled={loading.update}
                                    className='select-none'
                                    onClick={() => updateUser()}
                                >
                                    {
                                        loading.update ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Update</div>
                                    }
                                </button>
                            </td>
                            <td className="active" >
                                <button
                                    disabled={loading.delete}
                                    onClick={() => setLoad({ ...loading, modal: true })}
                                    className='select-none delete'
                                >
                                    {
                                        loading.delete ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Delete</div>
                                    }
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    );

}

export default UpdateUserView;