import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    orders: {
        orders: {
            data: []
        },
        orderServer: {
            data: []
        }
    }
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        SetOrders: (state, action) => {
            state.orders.orders = action.payload
        },
        SetOrdersServer: (state, action) => {
            state.orders.orderServer = action.payload
        },
        Orders_edit: (state, action) => {
            let b = state.orders.orders.data.map(order => {
                if (order.id !== action.payload.id) {
                    return order;
                } else {
                    return action.payload;
                }
            });
            state.orders.orders = {
                ...state.orders.orders,
                data: b
            }
        },
        Status_edit: (state, action) => {
            let b = state.orders.orders.data.map(order => {
                if (order.id !== action.payload.id) {
                    return order;
                } else {
                    // order.balance = action.payload.status
                    return {...order, status: action.payload.status};
                }
            });
            state.orders.orders = {
                ...state.orders.orders,
                data: b
            }
        },
        Status_server: (state, action) => {
            let b = state.orders.orderServer.data.map(order => {
                if (order.id !== action.payload.id) {
                    return order;
                } else {
                    // order.balance = action.payload.status
                    return {...order, status: action.payload.status};
                }
            });
            state.orders.orderServer = {
                ...state.orders.orderServer,
                data: b
            }
        },
        Delete_oders: (state, action) => {
            let a = state.orders.orders.data.filter(order => {
                return order.id !== action.payload.id;
            })
            state.orders.orders = {
                ...state.orders.orders,
                data: a
            }
        },
    },
})

export const {SetOrders, Orders_edit, Delete_oders, Status_edit, SetOrdersServer, Status_server} = ordersSlice.actions

export default ordersSlice.reducer