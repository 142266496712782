import { MdDashboard, MdOutlineMiscellaneousServices } from 'react-icons/md';
import { AiFillBank, AiOutlineApi, AiFillPicture } from 'react-icons/ai';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { RiSettings3Fill } from 'react-icons/ri';
import { CgUserList } from 'react-icons/cg';
import { BsClipboardMinus } from 'react-icons/bs';
import { IoIosLogOut } from 'react-icons/io';
import { MdOutlineSubscriptions } from 'react-icons/md';


export const Shortcuts = [
    {
        name: 'Dashboard',
        icon: <MdDashboard />,
        color: '#C3E88D',
        sub: [
            {
                subname: 'Dashboard',
                path: '/'
            }
        ]
    },
    {
        name: 'Users',
        icon: <CgUserList />,
        color: '#C792EA',
        sub: [
            {
                subname: '+Add-users/view',
                path: '/get-users',
                className: 'users',
                belong: 'users',
                item: [
                    {
                        name: 'View users',
                        path: '/get-users',
                        className: '/get-users',
                    },
                    {
                        name: '+Add user',
                        path: '/add-user',
                        className: '/add-user',
                    },
                ]
            },
            {
                subname: 'Customer group',
                path: '/customer-group',
                className: 'users',
                belong: 'users',
                item: [
                    {
                        name: 'Customer group',
                        path: '/customer-group',
                        className: '/customer-group',
                    }
                ]
            }
        ],
    },
    {
        name: 'Services',
        icon: <AiOutlineApi />,
        color: '#89DDFF',
        sub: [
            {
                subname: 'Service imei',
                path: '/get-services',
                className: 'services',
                belong: 'services',
                item: [
                    {
                        name: 'All services',
                        path: '/get-services',
                        className: '/get-services',
                    },
                    {
                        name: '+Add service',
                        path: '/add-service',
                        className: '/add-service',
                    }
                ]
            },
        ]
    },
    {
        name: 'Orders',
        icon: <BsClipboardMinus />,
        color: '#FFCB8B',
        sub: [
            {
                subname: 'Orders imei',
                path: '/get-orders',
                className: 'news',
                belong: 'orders',
                item: [
                    {
                        name: 'All orders',
                        path: '/get-orders',
                        className: '/get-orders',
                    },
                    {
                        name: 'Pending orders',
                        path: '/awaiting-orders',
                        className: '/awaiting-orders',
                    },
                    {
                        name: 'Answered orders',
                        path: '/answered-orders',
                        className: '/answered-orders',
                    },
                ]
            },
        ]
    },
    {
        name: 'Subscriptions',
        icon: <MdOutlineSubscriptions />,
        color: '#F78C6C',
        sub: [
            {
                subname: 'View/subscriptions',
                path: '/get-subscriptions',
                className: 'subscriptions',
                belong: 'subscriptions',
                item: [
                    {
                        name: 'View subscriptions',
                        path: '/get-subscriptions',
                        className: '/get-subscriptions',
                    },
                ]
            }
        ]
    },
    {
        name: 'Tickets',
        icon: <FaFileInvoiceDollar />,
        color: '#F78C6C',
        sub: [
            {
                subname: 'View/tickets',
                path: '/get-tickets',
                className: 'tickets',
                belong: 'tickets',
                item: [
                    {
                        name: 'View ticekts',
                        path: '/get-tickets',
                        className: '/get-tickets',
                    },
                ]
            }
        ]
    },
    {
        name: 'Banks',
        icon: <AiFillBank />,
        color: '#82AAFF',
        sub: [
            {
                subname: '+Add-banks/view',
                path: '/get-banks',
                className: 'banks',
                belong: 'banks',
                item: [
                    {
                        name: 'View banks',
                        path: '/get-banks',
                        className: '/get-banks',
                    },
                    {
                        name: '+Add bank',
                        path: '/add-bank',
                        className: '/add-bank',
                    },
                ]
            }
        ]
    },
    {
        name: '',
        icon: <IoIosLogOut />,
        color: 'blue',
        sub: [
            {
                subname: 'Sign out',
                path: '/',
                item: [
                    {
                        name: 'General',
                        path: '/general-settings',
                        className: '/general-settings',
                    }
                ]
            }
        ]
    },
]


export const Show = [
    {
        name: 'User',
        belong: 'user',
        className: '/get-users/user-info',
        path: '/get-users/user-info'
    },
    {
        name: 'Active',
        belong: 'user',
        className: '/get-users/user-press',
        path: '/get-users/user-press'
    },
    {
        name: 'Balance',
        belong: 'user',
        className: '/get-users/user-balance',
        path: '/get-users/user-balance'
    },
    {
        name: 'Subs',
        belong: 'user',
        className: '/get-users/user-subs',
        path: '/get-users/user-subs'
    },
    {
        name: 'Customers',
        belong: 'user',
        className: '/get-users/user-customers',
        path: '/get-users/user-customers'
    },
    {
        name: 'Tickets',
        belong: 'user',
        className: '/get-users/user-tickets',
        path: '/get-users/user-tickets'
    },
    {
        name: 'Orders',
        belong: 'user',
        className: '/get-users/user-orders',
        path: '/get-users/user-orders'
    },
    {
        name: 'Bank',
        belong: 'bank',
        className: '/get-banks/bank-info',
        path: '/get-banks/bank-info'
    },
    {
        name: 'Service',
        belong: 'service',
        className: '/get-services/service-info',
        path: '/get-services/service-info'
    },
    {
        name: 'Ticket',
        belong: 'ticket',
        className: '/get-tickets/ticket-info',
        path: '/get-tickets/ticket-info'
    },
    {
        name: 'Order',
        belong: 'order',
        className: '/get-orders/order-info',
        path: '/get-orders/order-info'
    },
]