import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {SetLogin} from '../store/storeReducers/AuthReducer';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {auth_prelogin} from '../functions/Authfunction';

const AppRoute = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.auth.loggedin);

    useEffect(() => {
        onSubmit();
    }, []);

    const onSubmit = async () => {
        try {
            const res = await auth_prelogin();
            dispatch(
                SetLogin(res.data)
            );
        } catch (error) {
            navigate(`/`);
        }
    }

    return (
        <div className='full-page'>
            {
                auth ?
                    <PrivateRoute/>
                    :
                    <PublicRoute/>
            }
        </div>
    )
}

export default AppRoute;