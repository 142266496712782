import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import JoditEditor from "jodit-react";
import { create_service } from "../../../functions/Servicefunction";
import { custom, active } from "../../../constants/status";
import { toType } from "../../../constants/status";

const AddServiceView = () => {
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState(false);
    const [group, setGroup] = useState([]);
    const [error, setErr] = useState({ err: [] });
    const [service, setService] = useState({
        name: '',
        time: '',
        description: '',
        price: '',
        protect_order: 1,
        custom_length: 1,
        length_min: 0,
        length_max: 15,
        only_letters: 0,
        only_numbers: 1,
        data_info: '',
        placeholder: '',
        model: 1,
        type: 'normal'
    });

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
    }, []);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });

        setService({
            ...service,
            [e.target.name]: e.target.value
        });
    };

    const createService = async () => {
        setLoad(true);
        try {
            const res = await create_service(service);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div >
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td className='fieldlabel'>Name:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Default price:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.price}
                                        name="price"
                                        type="number"
                                        placeholder="Price:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.price}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Delivery time:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.time}
                                        name='time'
                                        type="text"
                                        placeholder="Time:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                     <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.time}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Protect order:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='protect_order'
                                        onChange={(e) => handleChange(e)}
                                        value={service.protect_order}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Custom lenght:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='custom_length'
                                        onChange={(e) => handleChange(e)}
                                        value={service.custom_length}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                service.custom_length == true &&
                                <>
                                    <tr>
                                        <td className='fieldlabel'>Lenght min:</td>
                                        <td className='fieldlabel'>
                                            <input
                                                className='outline-none pd-0-10p'
                                                autoComplete="new-password"
                                                defaultValue={service.length_min}
                                                name="length_min"
                                                type='number'
                                                placeholder="min:"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.length_min}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='fieldlabel'>Lenght max:</td>
                                        <td className='fieldlabel'>
                                            <input
                                                className='outline-none pd-0-10p'
                                                autoComplete="new-password"
                                                defaultValue={service.length_max}
                                                name="length_max"
                                                type='number'
                                                placeholder="max:"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.length_max}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='fieldlabel'>Only letters:</td>
                                        <td className='fieldlabel'>
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='only_letters'
                                                onChange={(e) => handleChange(e)}
                                                value={service.only_letters}
                                            >
                                                {
                                                    custom.map((a, k) => {
                                                        return (
                                                            <option key={k} value={a.isActive}>
                                                                {a.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='fieldlabel'>Only numbers:</td>
                                        <td className='fieldlabel'>
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='only_numbers'
                                                onChange={(e) => handleChange(e)}
                                                value={service.only_numbers}
                                            >
                                                {
                                                    custom.map((a, k) => {
                                                        return (
                                                            <option key={k} value={a.isActive}>
                                                                {a.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className='fieldlabel'>Show model:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='model'
                                        onChange={(e) => handleChange(e)}
                                        value={service.model}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>To type:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='type'
                                        onChange={(e) => handleChange(e)}
                                        value={service.type}
                                    >
                                        {
                                            toType.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.value}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Data info:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.data_info}
                                        name="data_info"
                                        type='text'
                                        placeholder=". . . ."
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Placeholder:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.placeholder}
                                        name="placeholder"
                                        type='text'
                                        placeholder=". . . ."
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <button
                                        disabled={loading}
                                        className="select-none"
                                        onClick={() => createService()}
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ width: '60%' }}>
                        <JoditEditor
                            value={service.description}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setService((state) => ({ ...state, description: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default AddServiceView