import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { update_Line } from '../../../../store/storeReducers/LoadingReducer';
import { get_user } from '../../../../functions/Usersfunction';
import { ImagenStatus } from '../../../../components/ImagenStatus';
import moment from 'moment';
import Paginator from '../../../../components/Paginator';
import { AiOutlineEdit } from 'react-icons/ai';
import Search from '../../../../components/Search';

const CustomersUserView = () => {
    const param = useOutletContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [users, setUsers] =  useState({ data: [] });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getUsers();
        }

    }, [param.count]);

   
    const getUsers = async (value) => {
        try {
            const user = {
                id: param.id,
                road: 'users',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_user(user);
            if (res.data.users) {
                setUsers(res.data.users);
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }


    return(
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getUsers(p)}
                        value={"Search by email..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Fullname</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Email</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Active</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Membership</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Roles</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Balance</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Register day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.data.map((user, index) => {
                                        const active = ImagenStatus((user.active))
                                        const membership = ImagenStatus((user.membership))
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{user.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{user.fullname}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{user.email}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{active} </td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{membership}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{user.roles}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(user.credits).toFixed(2)}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(user.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`/get-users/user-info?profile_id=${user.id}`)} className="default_dg_td tx-center  dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer"><AiOutlineEdit /> </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={users}
                action={(p) => getUsers(p)}
            />
        </div>
    )
}

export default CustomersUserView;