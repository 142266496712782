import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    auth: {
        user: {},
        accessToken: '',
        loggedIn: false,
    },
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        SetLogin: (state, action) => {
            state.auth.access_token = action.payload.access_token
            state.auth.user = action.payload.user
            state.auth.loggedin = action.payload.loggedin
        }, SetOut: (state, action) => {
            state.auth.access_token = ''
            state.auth.loggedin = action.payload.loggedout
            state.auth.user = {}
        },
    },
})

// Action creators are generated for each case reducer function
export const {SetLogin, SetOut} = authSlice.actions

export default authSlice.reducer