import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {api} from "../../../api/ConnectApi";
import {SetBanks} from "../../../store/storeReducers/BanksReducer";
import JoditEditor from "jodit-react";
import {create_bank} from "../../../functions/Bankfunction";
import {active} from "../../../constants/status";

const AddBank = () => {
    const dispatch = useDispatch();
    const [loading, setLoad] = useState(false);
    const [error, setErr] = useState({err: []});
    const [bank, setBank] = useState({
        id: '',
        name: '',
        num_account: '',
        holder_name: '',
        description: '',
        isActive: true
    });

    const handleChange = (e) => {
        setBank({
            ...bank,
            [e.target.name]: e.target.value
        });
    };

    const createBank = async () => {
        setLoad(true);
        try {
            const res = await create_bank(bank);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div>
            <table className="table-add">
                <tbody>
                <tr>
                    <td>Name:</td>
                    <td>
                        <input
                            className='outline-none pd-0-10p'
                            autoComplete="new-password"
                            name='name'
                            type="text"
                            placeholder="Name:"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.name}
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Num Account:</td>
                    <td>
                        <input
                            className='outline-none pd-0-10p'
                            autoComplete="new-password"
                            name='num_account'
                            type="text"
                            placeholder="Num Account:"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.num_account}
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Holder Name:</td>
                    <td>
                        <input
                            className='outline-none pd-0-10p'
                            autoComplete="new-password"
                            name="holder_name"
                            type="text"
                            placeholder="Holder Name:"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.holder_name}
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Active Bank:</td>
                    <td>
                        <select
                            className='outline-none pd-0-10p'
                            onChange={(e) => handleChange(e)}
                            name="isActive"
                        >
                            {
                                active.map((act, k) => {
                                    return (
                                        <option key={k} value={act.active}>
                                            {act.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button
                            className="select-none"
                            disabled={loading}
                            onClick={() => createBank()}
                        >
                            {
                                loading ?
                                    <div className="spin update"></div>
                                    :
                                    <div>Add</div>
                            }
                        </button>
                    </td>
                    <div>
                            <span className="color-red ft-size_11 ft-family-monospace">
                                {error.description}
                            </span>
                    </div>
                </tr>
                </tbody>
            </table>
            <div style={{width: '60%'}}>
                <JoditEditor
                    value={bank.description}
                    // config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setBank((state) => ({...state, description: newContent}))}
                />
            </div>
        </div>
    );
}

export default AddBank