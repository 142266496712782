import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalDelete from "../../../../components/ModalDelete";
import { Update_banks, Delete_banks } from "../../../../store/storeReducers/BanksReducer";
import JoditEditor from "jodit-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { active } from "../../../../constants/status";
import { get_bank, update_bank, delete_bank } from "../../../../functions/Bankfunction";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const UpdateBank = () => {
    const dispatch = useDispatch();
    const param = useOutletContext();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [bank, setBank] = useState({
        id: '',
        name: '',
        num_account: '',
        holder_name: '',
        description: '',
        isActive: '',
        user:{
            email:''
        }
    });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getBank(param.id);
        }
    }, [param.count])

    const handleChange = (e) => {
        setBank({
            ...bank,
            [e.target.name]: e.target.value
        });
    };

    const getBank = async (id) => {
        try {
            const res = await get_bank({ id, action: 'bank' });
            setBank(res.data.bank);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateBank = async () => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_bank(bank);
            dispatch(Update_banks(bank))
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteBank = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_bank(bank.id);
            dispatch(Delete_banks(bank));
            alert(res.data.alert);
            navigate('/get-banks');
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteBank()}
            />
            {
                !line &&
                <div>
                    <table className="table-add">
                        <tbody>
                        <tr>
                            <td>User:</td>
                            <td>
                                <span  className="outline_none pd-0-10p">{bank.user.email}</span>
                            </td>
                            </tr>
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={bank.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Num Account:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={bank.num_account}
                                        name='num_account'
                                        type="text"
                                        placeholder="Num Account:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Holder Name:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={bank.holder_name}
                                        name="holder_name"
                                        type="text"
                                        placeholder="Holder Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Active Bank:</td>
                                <td>
                                    <select
                                        className='outline-none pd-0-10p'
                                        onChange={(e) => handleChange(e)}
                                        name="isActive"
                                        value={bank.isActive}
                                    >
                                        {
                                            active.map((act, k) => {
                                                return (
                                                    <option key={k} value={act.active}>
                                                        {act.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button
                                        disabled={loading.update}
                                        className="select-none"
                                        onClick={() => updateBank()}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Update</div>
                                        }
                                    </button>
                                </td>
                                <td className="active">
                                    <button
                                        disabled={loading.delete}
                                        className="select-none delete"
                                        onClick={() => setLoad({ ...loading, modal: true })}
                                    >
                                        {
                                            loading.delete ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Delete</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ width: '60%' }}>
                        <JoditEditor
                            value={bank.description}
                            // config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setBank((state) => ({ ...state, description: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdateBank