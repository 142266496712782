import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../../api/ConnectApi";
import { useSelector, useDispatch } from "react-redux";
import Moment from 'moment';
import Paginator from "../../../components/Paginator";
import { useOutletContext } from "react-router-dom";
import { get_orders, await_order } from "../../../functions/Ordersfunction";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";
import { Status_edit } from "../../../store/storeReducers/OrdersReducer";

const AwaitingOrdersView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [orders, setOrders] = useState({ data: [] });
    const [newOrders, setNeworders] = useState({});
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false
    });

    useEffect(() => {
        dispatch(update_Line(true));
        getOrders();
    }, [param.count]);// eslint-disable-line react-hooks/exhaustive-deps

    const toggleHandler = (item) => {
        setNeworders((state) => {
            return ({
                ...state,
                [item.id]: state[item.id]
                    ? null
                    : {
                        id: item.id
                    }
            });
        });
    };

    const getOrders = async (value) => {
        try {
            const order = {
                road: 'awaiting',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_orders(order);
            setOrders(res.data.orders);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const sendStatus = async () => {
        setLoad({ ...loading, update: true });
        try {
            const o = { road: 'in-waiting', orders: newOrders }
            const res = await await_order(o);
            if (res.data.orders) {
                res.data.orders.map((ord) => {
                    dispatch(Status_edit(ord));
                })
            }
            await getOrders();
        } catch (err) {
            if (err.response.data && err.response.data.err) {
                setErr(err.response.data.err);
            }
        }
        setNeworders({});
        setLoad({ ...loading, update: false });
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <div >
                        <span className="color-red ft-size_11 ft-family-monospace">
                            {error.orders}
                        </span>
                    </div>
                    <table className="default_dg_table" >
                        <thead>
                            <tr>
                                <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Imei</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Service</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Day</th>
                                <th className="default_dg_td tx-left default_dg_a_header">User</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Status</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.data.map((u, i) => {
                                    return (
                                        <tr key={i} className="dg_tr">
                                            <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1" >#{u.id}</td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{u.imei}</td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{u.service.name}</td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{Moment(u.created_at).format('Y-MM-DD')}</td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{u.user.email}</td>
                                            <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">
                                                <input
                                                    className="cursor-pointer"
                                                    onChange={() => toggleHandler(u)}
                                                    checked={newOrders[u.id] ? true : false}
                                                    type="checkbox"
                                                />
                                            </td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(u.price).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="table-add mg-top_20">
                        <button
                            disabled={loading.update}
                            className="select-none "
                            onClick={() => sendStatus()}
                        >
                            {
                                loading.update ?
                                    <div className="spin update"></div>
                                    :
                                    <div>Submit</div>
                            }
                        </button>
                    </div>
                </div>
            }
            < Paginator
                data={orders}
                action={(p) => getOrders(p)}
            />
        </div>
    );
}

export default AwaitingOrdersView