import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import IndexUsersView from '../views/users/IndexUsersView';
import GetUsersView from '../views/users/in-users/GetUsersView';
import AddUserView from '../views/users/in-users/AddUserView';
import IndexUserView from '../views/users/in-user/IndexUserView';
import UpdateUserView from '../views/users/in-user/user/UpdateUserView';
import IndexServicesView from '../views/services/IndexServicesView';
import GetServicesView from '../views/services/in-services/GetServicesView';
import AddServiceView from '../views/services/in-services/AddServiceView';
import IndexServiceView from '../views/services/in-service/IndexServiceView';
import UpdateServiceView from '../views/services/in-service/service/UpdateServiceView';
import IndexBanks from '../views/banks/IndexBanks';
import GetBanks from '../views/banks/in-banks/GetBanks';
import AddBank from '../views/banks/in-banks/AddBank';
import IndexBank from '../views/banks/in-bank/IndexBank';
import UpdateBank from '../views/banks/in-bank/bank/UpdateBank';
import IndexTickets from '../views/tickets/IndexTickets';
import GetTickets from '../views/tickets/in-tickets/GetTickets';
import BalanceUserView from '../views/users/in-user/user/BalanceUserView';
import TicketsUserView from '../views/users/in-user/user/TicketsUserView';
import IndexTicket from '../views/tickets/in-ticket/IndexTicket';
import UpdateTciket from '../views/tickets/in-ticket/ticket/UpdateTciket';
import OrdersUserView from '../views/users/in-user/user/OrdersUserView';
import AllOrdersView from '../views/orders/in-orders/AllOrdersView';
import IndexOrdersView from '../views/orders/IndexOrdersView';
import AwaitingOrdersView from '../views/orders/in-orders/AwaitingOrdersView';
import AnsweredOrdersView from '../views/orders/in-orders/AnsweredOrdersView';
import IndexOrderView from '../views/orders/in-order/IndexOrderView';
import UpdateOrderView from '../views/orders/in-order/order/UpdateOrderView';
import Line from '../components/Line';
import CustomerGroupView from '../views/users/in-users/CustomerGroupView'
import CustomersUserView from '../views/users/in-user/user/CustomersUserView';
import PressUserView from '../views/users/in-user/user/PressUserView';
import SubsUserView from '../views/users/in-user/user/SubsUserView';
import IndexSubsView from '../views/subscriptions/IndexSubsView';
import GetSubsView from '../views/subscriptions/in-subs/GetSubsView';


const PrivateRoute = () => {
    return (
        <div className='content-prvt dp-flex'>
            <Navbar/>
            <div className='all-content dp-flex'>
                <Sidebar/>
                <div className='con-tent mg-bottom_100'>
                    <Line/>
                    <Routes>
                        <Route path='/' element={<span>Hola</span>}/>
                        <Route path='/' element={<IndexUsersView/>}>
                            <Route path='get-users' element={<GetUsersView/>}/>
                            <Route path='add-user' element={<AddUserView/>}/>
                            <Route path='customer-group' element={<CustomerGroupView/>}/>
                        </Route>
                        <Route path='/' element={<IndexUserView/>}>
                            <Route path='get-users/user-info' element={<UpdateUserView/>}/>
                            <Route path='get-users/user-press' element={<PressUserView/>}/>
                            <Route path='get-users/user-balance' element={<BalanceUserView/>}/>
                            <Route path='get-users/user-subs' element={<SubsUserView/>}/>
                            <Route path='get-users/user-customers' element={<CustomersUserView/>}/>
                            <Route path='get-users/user-tickets' element={<TicketsUserView/>}/>
                            <Route path='get-users/user-orders' element={<OrdersUserView/>}/>
                        </Route>
                        <Route path='/' element={<IndexServicesView/>}>
                            <Route path='get-services' element={<GetServicesView/>}/>
                            <Route path='add-service' element={<AddServiceView/>}/>
                        </Route>
                        <Route path='/' element={<IndexServiceView/>}>
                            <Route path='get-services/service-info' element={<UpdateServiceView/>}/>
                        </Route>
                        <Route path='/' element={<IndexBanks/>}>
                            <Route path='get-banks' element={<GetBanks/>}/>
                            <Route path='add-bank' element={<AddBank/>}/>
                        </Route>
                        <Route path='/' element={<IndexBank/>}>
                            <Route path='get-banks/bank-info' element={<UpdateBank/>}/>
                        </Route>
                        <Route path='/' element={<IndexSubsView/>}>
                            <Route path='get-subscriptions' element={<GetSubsView/>}/>
                        </Route>
                        <Route path='/' element={<IndexTickets/>}>
                            <Route path='get-tickets' element={<GetTickets/>}/>
                        </Route>
                        <Route path='/' element={<IndexTicket/>}>
                            <Route path='get-tickets/ticket-info' element={<UpdateTciket/>}/>
                        </Route>
                        <Route path='/' element={<IndexOrdersView/>}>
                            <Route path='get-orders' element={<AllOrdersView/>}/>
                            <Route path='awaiting-orders' element={<AwaitingOrdersView/>}/>
                            <Route path='answered-orders' element={<AnsweredOrdersView/>}/>
                        </Route>
                        <Route path='/' element={<IndexOrderView/>}>
                            <Route path='get-orders/order-info' element={<UpdateOrderView/>}/>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default PrivateRoute