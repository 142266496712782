import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginView from '../views/auth/LoginView';

const PublicRoute = () => {
    return (
        <Routes>
            <Route path='/' element={<LoginView/>}/>
        </Routes>
    )
}

export default PublicRoute