import axiosConfig from '../axiosConfig';
import {api} from '../api/ConnectApi';

export const auth_prelogin = () => {
    var url = `${api}/autoLogin`;
    return axiosConfig.get(`${url}`, {}).then(response => response);
}

export const sign_out = () => {
    var url = `${api}/logout`;
    return axiosConfig.get(`${url}`, {}).then(response => response);
}