import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useOutletContext} from "react-router-dom";
import ModalDelete from "../../../../components/ModalDelete";
import JoditEditor from "jodit-react";
import {Orders_edit, Delete_oders, Status_edit} from '../../../../store/storeReducers/OrdersReducer';
import {Balance_users} from '../../../../store/storeReducers/UsersReducer';
import {status} from '../../../../constants/status';
import {get_order, update_order, delete_order} from '../../../../functions/Ordersfunction';
import {update_Line} from '../../../../store/storeReducers/LoadingReducer';

const UpdateOrderView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const navigate = useNavigate();
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [order, setOrder] = useState({
        id: '',
        user_id: '',
        service_id: '',
        status: '',
        imei: '',
        response: '',
        service: {
            name: ''
        },
        user: {
            email: ''
        },
    });

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getOrder(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setOrder({
            ...order,
            [e.target.name]: e.target.value
        });
    };

    const getOrder = async (id) => {
        try {
            const res = await get_order({id, road: 'order'});
            setOrder(res.data.order);
            dispatch(Orders_edit(res.data.order));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const update_Order = async () => {
        setLoad({...loading, update: true});
        try {
            const newOrder = {...order, road: 'order'};
            const res = await update_order(newOrder);
            if (res.data.order) {
                dispatch(Status_edit(res.data.order))
            }
            if (res.data.user) {
                dispatch(Balance_users(res.data.user))
            }
            alert(res.data.alert)
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({...loading, update: false});
    }

    const deleteOrder = async () => {
        setLoad({...loading, modal: false, delete: true});

        try {
            const res = await delete_order(order.id);
            dispatch(Delete_oders(order))
            alert(res.data.alert)
            navigate('/get-orders');
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({...loading, delete: false});
    }


    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({...loading, modal: !loading.modal})}
                action={() => deleteOrder()}
            />
            {
                !line &&
                <div>
                    <table className='table-add'>
                        <tbody>
                        <tr>
                            <td className="fieldlabel">Num order:</td>
                            <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.id}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Imei:</td>
                            <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.imei}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Service:</td>
                            <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.service.name}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Service price:</td>
                            <td className='fieldlabel'>
                                    <span
                                        className='outline-none '>
                                        {parseFloat(order.price).toFixed(2)}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Status:</td>
                            <td className='fieldlabel'>
                                <select
                                    className='outline-none pd-0-10p'
                                    value={order.status}
                                    name="status"
                                    onChange={(e) => handleChange(e)}
                                >
                                    {
                                        [{"name": "--Select Status--", status: ""}, ...status].map((s, k) => {
                                            return (
                                                <option key={k} value={s.status}>
                                                    {s.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">User:</td>
                            <td className='fieldlabel'>
                                    <span
                                        className='outline-none '>
                                        {order.user.email}
                                    </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="fieldlabel" style={{width: '60%'}}>
                        <JoditEditor
                            value={order.response}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setOrder((state) => ({...state, response: newContent}))}
                        />
                    </div>
                    <div className='table-add dp-flex bttns active'>
                        <button
                            disabled={loading.update}
                            className="select-none"
                            onClick={() => update_Order()}
                        >
                            {
                                loading.update ?
                                    <div className="spin update"></div>
                                    :
                                    <div>Update</div>
                            }
                        </button>
                        <button
                            disabled={loading.delete}
                            className="select-none delete"
                            onClick={() => setLoad({...loading, modal: true})}
                        >
                            {
                                loading.delete ?
                                    <div className="spin update"></div>
                                    :
                                    <div>Delete</div>
                            }
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateOrderView